import React, { useState } from 'react';
import styled from 'styled-components';
import Counts from '../Counts';
import Editor from '../Editor';
import { updateCounts } from './updateCounts';

const Container = styled.div`
    font-family: roboto;
    margin: auto;
    max-width: 1100px;
    padding: 0 20px;
    text-align: center;
`;

const Footer = styled.footer`
    color: #5c5f58;
    text-align: right;
`;

function App() {
    const [characterCount, setCharacterCount] = useState(0);
    const [wordCount, setWordCount] = useState(0);
    return (
        <Container>
            <div className="App-Content">
                <Counts {...{ characterCount, wordCount }} />
                <Editor
                    updateCounts={updateCounts(setCharacterCount, setWordCount)}
                />
            </div>
            <Footer>
                Created by <a href="https://keybase.io/danco">Dan Coulter</a>{' '}
                and <a href="http://mpwriter.com">Mike Peterson</a>
            </Footer>
        </Container>
    );
}

export default App;
