import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const fontSize = css`
    @media only screen and (min-width: 500px) {
        font-size: 30px;
    }

    @media only screen and (max-width: 500px) {
        font-size: 20px;
    }
`;

const CharacterCount = styled.div`
    ${fontSize}
    @media only screen and (min-width: 500px) {
        float: left;
    }

    @media only screen and (max-width: 500px) {
        text-align: left;
    }
`;

const WordCount = styled.div`
    ${fontSize}
    @media only screen and (min-width: 500px) {
        float: right;
    }

    @media only screen and (max-width: 500px) {
        text-align: left;
    }
`;

const Counts = ({ characterCount, wordCount }) => (
    <div>
        <CharacterCount>Characters: {characterCount}</CharacterCount>
        <WordCount>Words: {wordCount}</WordCount>
    </div>
);

export default Counts;

Counts.propTypes = {
    characterCount: PropTypes.number,
    wordCount: PropTypes.number,
};

Counts.defaultProps = {
    characterCount: 0,
    wordCount: 0,
};
