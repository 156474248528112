import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TextArea = styled.textarea`
    font-family: roboto;
    height: 400px;
    width: 100%;
`;

const Editor = ({ updateCounts }) => {
    return (
        <TextArea
            data-testid="editor"
            onChange={e => {
                updateCounts(
                    e.target.value ? e.target.value.length : 0,
                    e.target.value
                        ? e.target.value.match(/\b[^ ]+\b/gi).length
                        : 0,
                );
            }}
        />
    );
};

Editor.propTypes = {
    updateCounts: PropTypes.func,
};

Editor.defaultProps = {
    updateCounts: () => {},
};

export default Editor;
